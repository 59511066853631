import React from 'react';
import './AboutUsHome.css';
import AlwashimLogo from '../../Images/AlwashimLogo.svg'

function AboutUsHome() {
    const y = localStorage.getItem("AboutEn")
    return (
        <React.Fragment>
            <div id="about-home">

                <img id="about-logo" src={AlwashimLogo} alt="AlwashimLogo" />

                <div id="about-home-text">

                    {y}

                    <button id="about-btn">Read More</button>


                </div>


            </div>



        </React.Fragment>
    )
}

export default AboutUsHome