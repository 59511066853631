import React from 'react'
import Banner from '../Banner/Banner.js';
import AboutUsHome from '../AboutUsHome/AboutUsHome.js';
import OrderNow from '../OrderNow/OrderNow.js';



function Home() {
    return (
        <div>
            <Banner />
            <AboutUsHome />
            <OrderNow />
        </div>
    )
}

export default Home