import './App.css';
import React from 'react';
import NavBar from './Components/NavBar/NavBar.js';
import Home from './Components/Home/Home.js';
import About from './Components/About/About.js';
import ContatcUs from './Components/ContatcUs/ContatcUs.js';
import Footer from './Components/Footer/Footer.js';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function App() {

  const { i18n } = useTranslation();
  document.body.dir = i18n.dir();
  return (
    <BrowserRouter>
      <div className="App">

        <NavBar />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<ContatcUs />} />
        </Routes>
        <Footer />


      </div>
    </BrowserRouter>
  );
}

export default App;
