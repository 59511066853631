import React, { useState } from 'react';
import './ContactUs.css';
import insta from '../../Images/instagramContact.svg';
import twitter from '../../Images/facebookContact.svg';
import whatsappImg from '../../Images/whatappContact.svg'
import { useTranslation } from 'react-i18next';

function ContatcUs() {
    const { t } = useTranslation();

    const linkOne = localStorage.getItem("whatsLink");
    const linkTwo = localStorage.getItem("instagramLink");
    const linkThree = localStorage.getItem("twitter");
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [Message, SetMessage] = useState("");
    const [messageAfter, setMessageAfter] = useState("");

    let handleSubmit = async (e) => {
        e.preventDefault();


        const requea = {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                name: name,
                email: email,
                subject: subject,
                Message: Message

            })
        }
        try {
            let res = await fetch("https://dashboard.alwashim.digisolapps.com/api/InsertToForm", requea)




            await res.json();
            if (res.status < 300 || res.status >= 200) {
                setName("");
                setEmail("");
                setSubject("");
                SetMessage("");
                setMessageAfter("Message sent");

            } else {
                setMessageAfter("Some error occured");
            }
        } catch (err) {
            console.log(err);
        }
    };
    return (
        <React.Fragment>

            <div className="App">
                <div id="followUs">
                    <h1 id="followUsTitle">{t("FollowUs")}:</h1>
                    <a href={linkTwo} alt="Instagram link"> <img className="footerInstaContact" src={insta} alt="Insta Logo" /></a>
                    <a href={linkThree} alt="Facebook link"> <img className="footerInstaContact" src={twitter} alt="Insta Logo" /></a>
                    <a href={linkOne} alt="whatsapp link">  <img className="footerInstaContact" src={whatsappImg} alt="Insta Logo" /></a>

                </div>
                <div id="contact-container">
                    <form onSubmit={handleSubmit} id="FormId">

                        <div id="lineOne">
                            <input id="nameField"
                                type="text"
                                value={name}
                                placeholder="Name"
                                onChange={(e) => setName(e.target.value)}
                                required
                            />
                            <input id="emailField"
                                type="email"
                                value={email}
                                placeholder="Email"
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </div>


                        <input
                            id="subjectLine"
                            type="text"
                            value={subject}
                            placeholder="Subject"
                            onChange={(e) => setSubject(e.target.value)}
                            required
                        />

                        <textarea
                            type="text"
                            value={Message}
                            placeholder="Message"
                            onChange={(e) => SetMessage(e.target.value)}
                            required
                        />
                        <button id="buttonSender" type="submit">Send Message</button>

                        <div className="message">{messageAfter ? <p>{messageAfter}</p> : null}</div>
                    </form>
                </div>

            </div>

        </React.Fragment>
    )
}

export default ContatcUs