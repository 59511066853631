import React from 'react';
import './Banner.css';
import axios from 'axios';
const Images = "https://dashboard.alwashim.digisolapps.com/api/getPanner";



axios.get(Images).then((responseLinksThree) => {
    const imagesPanner = responseLinksThree.data[0].smallpic;
    localStorage.setItem("smallpic", imagesPanner)
    const imagesPannerTwo = responseLinksThree.data[0].checkin
    localStorage.setItem("checkin", imagesPannerTwo)
    console.log(imagesPanner);
});
const getBig = localStorage.getItem("checkin")
const getSmall = localStorage.getItem("smallpic")

function Banner() {
    return (
        <React.Fragment>

            <div id="banner" dir='ltr'>
                <img id="bannerImg" src={getBig} alt="Checkin" />

                <img id="bannerImgTwo" src={getSmall} alt="Checkin" />


            </div>
        </React.Fragment>
    )
}

export default Banner