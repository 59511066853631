import React from 'react';
import aboutLogo from '../../Images/AlwashimLogo.svg';
import Banner from '../Banner/Banner.js';
import { useTranslation } from 'react-i18next';
import './About.css'
function About() {
    const { t } = useTranslation();



    const About = localStorage.getItem("AboutEn");
    const ourVision = localStorage.getItem("OurVision");
    const ourmission = localStorage.getItem("OurMission");
    return (

        <div id="about">

            <Banner />
            <div id="About-container-Line">
                <img id="AboutLogo" src={aboutLogo} alt="AlwashimLogo" />

                <div id="aboutDescription">{About}
                </div>
            </div>



            <div id="About-container-LineTwo">

                <div id="ourVision">
                    <div id="ourVisionTitle">
                        {t("OurVision")}

                    </div>
                    <div id="ourVisionText" align="justify">

                        {ourVision}

                    </div>

                </div>


                <div id="ourMission">

                    <div id="ourMissionTitle">
                        {t("OurMission")}
                    </div>

                    <div id="ourMissionText" align="justify">
                        {ourmission}
                    </div>
                </div>


            </div>


        </div>
    )
}

export default About