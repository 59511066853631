import React, { useEffect } from 'react';
import './NavBar.css';
import AlwashimLogo from '../../Images/AlwashimLogo.svg';
import { useTranslation } from 'react-i18next';

import axios from "axios";








function NavBar() {

    const { t, i18n } = useTranslation();
    const changeLanguage = (language) => {
        i18n.changeLanguage(language)
    }


    const baseURL = `https://dashboard.alwashim.digisolapps.com/api/about`;






    useEffect(() => {

        axios.get(baseURL).then((response) => {
            const aboutText = response.data.data[0].about;
            const ourVision = response.data.data[0].our_vision;
            const ourMission = response.data.data[0].our_mission;
            const description = response.data.data[0].Description;

            localStorage.setItem("AboutEn", aboutText);
            localStorage.setItem("OurVision", ourVision);
            localStorage.setItem("OurMission", ourMission);
            localStorage.setItem("description", description);
        });
    }, [baseURL]);


    /****************************************************************/


    return (


        <React.Fragment>


            <div id="nav">

                <img id="navLogo" src={AlwashimLogo} alt={"Alwashim Logo"} />
                <ul id="navMainList">
                    <li className="navItem"><a href='/'>{t("Home")}</a> </li>
                    <li className="navItem"><a href='/about'>{t("About")}</a></li>
                    <li className="navItem"><a href='/'> {t("DownloadNow")}</a></li>
                    <li className="navItem"><a href='/contact'>{t("ContacUs")}</a></li>
                    <div id="lang">
                        <select>
                            <option onClick={() => changeLanguage("en")}>EN</option>
                            <option onClick={() => changeLanguage("ar")}>AR</option>
                        </select>
                    </div>
                </ul>









            </div>


        </React.Fragment>
    )
}

export default NavBar