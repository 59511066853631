import React from 'react';
import './Footer.css';
import footerLogo from '../../Images/FooterLogo.svg';
import insta from '../../Images/insta.svg';
import twitter from '../../Images/twitter.svg';
import whatsappImg from '../../Images/whatsapp.svg';
import axios from 'axios';
const appLinks = "https://dashboard.alwashim.digisolapps.com/api/getApplinks";
const digisol = "https://digisolfze.com/"

function Footer() {

    axios.get(appLinks).then((responseLinks) => {
        const whatsDeclared = responseLinks.data[0].whats_app_number;
        const instaGram = responseLinks.data[0].instagram_url;
        const facebook = responseLinks.data[0].facebook_url;
        localStorage.setItem("whatsLink", whatsDeclared);
        localStorage.setItem("instagramLink", instaGram);
        localStorage.setItem("twitter", facebook);
    });
    const linkOne = localStorage.getItem("whatsLink");
    const linkTwo = localStorage.getItem("instagramLink");
    const linkThree = localStorage.getItem("twitter");
    return (
        <React.Fragment>

            <div id="footer">
                <img id="footerLogoWhite" src={footerLogo} alt="Alwashim Logo" />


                <spna id="powered">2022 &copy; Powered By <a id="digisolFze" href={digisol} alt="Digisol"><u>Digisol</u></a></spna>



                <div id="footerSocial">
                    <a href={linkTwo} alt="Instagram link"> <img className="footerInsta" src={insta} alt="Insta Logo" /></a>
                    <a href={linkThree} alt="Facebook link"> <img className="footerInsta" src={twitter} alt="Insta Logo" /></a>
                    <a href={linkOne} alt="whatsapp link">  <img className="footerInsta" src={whatsappImg} alt="Insta Logo" /></a>

                </div>


            </div>

        </React.Fragment>
    )
}

export default Footer