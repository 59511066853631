import React, { useEffect } from 'react';
import './OrderNow.css';
import iphone from '../../Images/Iphone.png';
import googlePlay from '../../Images/googlePlay.svg';
import appStore from '../../Images/appStore.svg';
import axios from "axios";
import { useTranslation } from 'react-i18next';

const baseURLTwo = 'https://dashboard.alwashim.digisolapps.com/api/getApplinks';

function OrderNow() {
    const { t } = useTranslation();



    useEffect(() => {
        axios.get(baseURLTwo).then((responseTwo) => {

            const googlePlayUrl = responseTwo.data[0].google_play_url;
            const appStoreUrl = responseTwo.data[0].app_store_url;
            console.log(appStoreUrl);
            localStorage.setItem("googleUrl", googlePlayUrl);
            localStorage.setItem("IosUrl", appStoreUrl)
            console.log(googlePlay);


        });
    }, []);


    const description = localStorage.getItem("description");
    const googleReal = localStorage.getItem("googleUrl")
    const IosReal = localStorage.getItem("IosUrl")

    return (
        <React.Fragment>

            <div id="order-now-container">
                <div id="order-now-title">{t("DownloadNow")}</div>
                <div id="order-now-container-Two">

                    <img id="iphone" src={iphone} alt="Download The App" />
                    <div id="order-now-container-three">
                        <div id="download-title">Download The Application</div>
                        <div id="download-description">{description}</div>
                        <a href={googleReal} alt="Google Play"> <img className="download" src={googlePlay} alt="Download Now From Google Play" /></a>
                        <a href={IosReal} alt="App Store"> <img className="download" src={appStore} alt="Download Now From Google Play" /></a>

                    </div>
                </div>
            </div>


        </React.Fragment>
    )
}

export default OrderNow